import React, { Fragment, useEffect } from "react"
import { graphql } from "gatsby"
import { injectIntl, navigate } from "gatsby-plugin-intl"

import Testimonials from "../../../components/Layout/Testimonials"
import Footer from "../../../components/Layout/Footer"
import SeoComp from "../../../components/SeoComp"
import OfferExclusifProductSection from "../../../components/OfferExclusifProductSection"

const Offres = ({ intl, data }) => {
  useEffect(() => {
    if (intl.locale != "fr") navigate(`/${intl.locale}/shop/`)
  }, [intl.locale])
  return (
    data &&
    process.env &&
    (process.env.GATSBY_ACTIVE_ENV === "eur" ||
      process.env.GATSBY_ACTIVE_ENV === "general") &&
    intl.locale === "fr" &&
    data.allDirectusOfferPageTranslation.nodes.length > 0 && (
      <Fragment>
        <SeoComp
          description={
            data.allDirectusSeoTranslation.nodes[0] &&
            data.allDirectusSeoTranslation.nodes[0].description
          }
          title={
            data.allDirectusSeoTranslation.nodes[0] &&
            data.allDirectusSeoTranslation.nodes[0].title
          }
          twitter_card={
            data.allDirectusSeoTranslation.nodes[0] &&
            data.allDirectusSeoTranslation.nodes[0].twitter_card
          }
          image={
            data.allDirectusSeoTranslation.nodes[0] &&
            data.allDirectusSeoTranslation.nodes[0].image &&
            data.allDirectusSeoTranslation.nodes[0].image.data &&
            data.allDirectusSeoTranslation.nodes[0].image.data.url
          }
          hreflangPathname={{
            fr: "shop/offres-exclusives",
            en: "",
            de: "",
            es: "",
          }}
          noindex={true}
          nofollow={true}
        />

        {data.allDirectusOfferPageTranslation.nodes[0]
          .exclusif_product_section && (
          <OfferExclusifProductSection
            data={
              data.allDirectusOfferPageTranslation.nodes[0]
                .exclusif_product_section
            }
          />
        )}

        {data.allDirectusCommonTranslation.nodes.length > 0 && (
          <Testimonials
            data={data.allDirectusCommonTranslation.nodes[0].testimonials.list}
          />
        )}

        {data.allDirectusFooterTranslation.nodes.length > 0 && (
          <Footer data={data.allDirectusFooterTranslation.nodes[0]} />
        )}
      </Fragment>
    )
  )
}

export const query = graphql`
  query($lang: String!) {
    site {
      siteMetadata {
        title
      }
    }
    allDirectusOfferPageTranslation(filter: { language: { eq: $lang } }) {
      nodes {
        exclusif_product_section {
          section_title
          banner_image
          banner_mob_image
          banner_alt
          bottom_text
          list {
            image
            alt
            video
            name
            title
            text
            top_text
            button_text
            button_url
          }
        }
      }
    }
    allDirectusCommonTranslation(filter: { language: { eq: $lang } }) {
      nodes {
        testimonials {
          list {
            image
            text
          }
        }
      }
    }
    allDirectusFooterTranslation(filter: { language: { eq: $lang } }) {
      nodes {
        facebook
        gbp_facebook
        youtube
        twitter
        instagram
        linkedin
        about_us {
          name
          list {
            link
            list
          }
        }
        contact {
          name
          list {
            link
            list
          }
          button {
            link
            list
          }
        }
        purchases {
          list {
            list
            link
          }
          name
        }
        help {
          list {
            link
            list
          }
          name
        }
        about_us_menu {
          name
          list {
            link
            list
          }
        }
        knowledge_center_menu {
          name
          list {
            link
            list
          }
        }
        shop_menu {
          name
          list {
            link
            list
          }
        }
        support_menu {
          name
          list {
            link
            list
          }
          button {
            link
            list
          }
        }
        newsletter_heading
        follow_us_heading
        footer_bottom {
          list {
            link
            list
          }
        }
      }
    }
    allDirectusSeoTranslation(
      filter: {
        language: { eq: $lang }
        seo: { page_name: { eq: "offres_exclusives" } }
      }
    ) {
      nodes {
        description
        title
        twitter_card
        twitter_title
        url
        image {
          data {
            url
          }
        }
      }
    }
  }
`

export default injectIntl(Offres)
